<template>
  <div class="js-common-wrap js-system-usage-installAccount">
    <div class="js-common-head">
     <div>
        <el-button class="back-btn" @click="$goBack" icon="el-icon-arrow-left" size="small" type="primary" plain></el-button>
        <label class="label">设备授权</label>
      </div>
    </div>
    <div class="js-common-content">
      <el-table class="js-table" :data="tableData" height="100%" empty-text="暂无数据">
        <el-table-column prop="id" label="设备ID"></el-table-column>
        <el-table-column label="授权时间">
          <template slot-scope="scope">
            {{scope.row.dateTime | timesToDatetime}}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <common-pagination></common-pagination>
  </div>
</template>
<script>
import CommonPagination from '@/components/CommonPagination'
export default {
  name: '',
  components: {
    CommonPagination
  },
  data() {
    return {
      tableData: [{
        id: 'adjkashdakjsdkja',
        dateTime: '1644814708285'
      },{
        id: 'adjkashdakjsdkja',
        dateTime: '1644814708285'
      }],
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import '@/assets/scss/variable.scss';
.js-system-usage-installAccount {
  .js-common-content {
    .js-table {
      tr{
        cursor: default;
      }
    }
  }
}
</style>
